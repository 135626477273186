/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
import { useEffect, useState } from 'react'
import axios from 'axios'
import classnames from 'classnames'
import { useHistory, useLocation } from 'react-router-dom'
import useUser from '../../hooks/useUser'
import './index.scss'
import SubmissionForm from '../SubmissionForm'
import { ReactComponent as Sort } from '../../assets/icons/sort.svg'
import { ReactComponent as ArrowDown } from '../../assets/icons/arrow-down-solid.svg'
import StepperDots from '../common/StepperDots'
import Filters from '../Filters'
import FiltersMobile from '../FiltersMobile'

const MobileCard = ({ submission, onOpen }) => (
  <div className="mobile-card mb-2" onClick={() => onOpen(submission.id)}>
    <div className={classnames('read-status', { readed: submission.readed })} />
    <div className="d-flex justify-content-between align-items-center p-2">
      <strong>COMPANY</strong>
      <span>{submission.company}</span>
    </div>
    <div className="d-flex justify-content-between align-items-center p-2">
      <strong>INQUIRY TYPE</strong>
      <span>{submission.inquiries_types_label}</span>
    </div>
    <div className="d-flex justify-content-between align-items-center p-2">
      <strong>AMOUNT</strong>
      <span>{submission.amount}</span>
    </div>
    <div className="d-flex justify-content-between align-items-center p-2">
      <strong>NAME</strong>
      <span>{submission.contact_name}</span>
    </div>
    <div className="d-flex justify-content-between align-items-center p-2">
      <strong>EMAIL</strong>
      <span>{submission.contact_email}</span>
    </div>
    <div className="d-flex justify-content-between align-items-center p-2">
      <strong>NETWORK</strong>
      <span>{submission.network}</span>
    </div>
    <div className="d-flex justify-content-between align-items-center p-2">
      <strong>HANDLE</strong>
      <span>{submission.account_handle}</span>
    </div>
    <div className="d-flex justify-content-between align-items-center p-2">
      <strong>TIMESTAMP</strong>
      <span>{new Date(submission.created_at).toDateString()}</span>
    </div>
  </div>
)

const Submissions = ({ submissions, submissionsPerPage, setSubmissionsPerPage, page, setPage, setSubmissions, submissionFormSlug, setModalContent, modalContent }) => {
  const [sortCriteria, setSortCriteria] = useState('created_at')
  const [apliedFilters, setApliedFilters] = useState([])
  const [double, setDouble] = useState(true)
  const [step, setStep] = useState(0)
  const [viewMore, setViewMore] = useState(false)
  const [touchStart, setTouchStart] = useState(0)
  const [touchEnd, setTouchEnd] = useState(0)

  const handleTouchStart = (e) => {
    setTouchStart(e.targetTouches[0].clientX)
  }
  const handleTouchMove = (e) => {
    setTouchEnd(e.targetTouches[0].clientX)
  }

  const API_URL = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}`
  const { userData } = useUser()
  axios.defaults.baseURL = API_URL
  axios.defaults.headers['Content-Type'] = 'application/json'
  axios.defaults.headers.Authorization = `bearer ${userData?.token}`
  const location = useLocation()
  const history = useHistory()

  const closeModal = () => setModalContent(-1)
  const openModal = (id) => {
    axios.create().post(
      'submission/readed',
      { id }
    )
      .then(() => {
        const newsubmissions = submissions.map((submission) => (submission.id === id ? { ...submission, readed: true } : submission))
        setSubmissions(newsubmissions)
      })
      .catch((error) => {
        console.log(error)
      })
    setModalContent(id)
    history.replace(`${location.pathname}?slug=${submissionFormSlug}&id=${id}`)
  }

  useEffect(() => { }, [apliedFilters, modalContent])

  const sortBy = (criteria) => {
    if (sortCriteria === criteria) {
      setDouble(!double)
      const newSubmissions = [...submissions].reverse()
      setSubmissions(newSubmissions)
    } else {
      setDouble(false)
      setSortCriteria(criteria)
      setSubmissions(submissions.sort((a, b) => {
        if (a[criteria] < b[criteria]) {
          return -1
        }
        if (a[criteria] > b[criteria]) {
          return 1
        }
        return 0
      }))
    }
  }
  const filterBy = (values, filter) => {
    switch (filter.key) {
      case 'company':
        return values.filter((submission) => submission.company.toLowerCase().includes(filter.value.toLowerCase())
        || submission.contact_name.toLowerCase().includes(filter.value.toLowerCase()))
      case 'from':
        return values.filter((submission) => submission.created_at >= filter.value)
      case 'to':
        return values.filter((submission) => submission.created_at <= filter.value)
      case 'network':
        return values.filter((submission) => filter.value.toLowerCase() === submission.network.toLowerCase())
      case 'handle':
        return values.filter((submission) => filter.value.toLowerCase() === submission.account_handle.toLowerCase())
      default:
        return values
    }
  }
  const applyFilters = (filter) => {
    setPage(1)
    const newApliedFilters = [...apliedFilters.filter((apliedFilter) => apliedFilter.key !== filter.key), filter]
    setApliedFilters(newApliedFilters)
  }
  const removeFilters = (filter) => {
    const newApliedFilters = [...apliedFilters.filter((apliedFilter) => apliedFilter.key !== filter.key)]
    setApliedFilters(newApliedFilters)
  }
  useEffect(() => { sortBy('created_at') }, [])
  const filterSubmissions = apliedFilters.reduce((acc, filter) => filterBy(acc, filter), submissions)
  const trySetStep = (step) => {
    if (filterSubmissions[step]) {
      setStep(step)
    }
  }
  const handleTouchEnd = () => {
    if (touchEnd && touchStart - touchEnd > 100 && step < 5) {
      trySetStep(step + 1)
    }
    if (touchEnd && touchStart - touchEnd < -100 && step > 0) {
      trySetStep(step - 1)
    }
    setTouchStart(0)
    setTouchEnd(0)
  }
  return (
    <>
      {modalContent > -1 && (
      <div className="modal-container">
        <div className="modal-deal">
          <button type="button" className="close" aria-label="Close" onClick={closeModal}>
            <span aria-hidden="true">&times;</span>
          </button>
          <SubmissionForm isModal content={submissions?.find((submission) => submission.id === modalContent)} submissionFormSlug={submissionFormSlug} />
        </div>
      </div>
      )}
      <h2 className="px-2">Submissions</h2>
      {submissions.length > 0
        ? (
          <>
            <div className="d-none d-lg-block">
              <Filters apliedFilers={apliedFilters} applyFilters={applyFilters} submissions={submissions} removeFilters={removeFilters} />
            </div>
            <table className="table-home d-none d-lg-table px-2">
              <tr>
                <th> </th>
                <th
                  id="companyName"
                  onClick={() => sortBy('company')}
                >COMPANY {sortCriteria === 'company' ? <ArrowDown width="10" transform={`rotate(${double ? '180' : '0'})`} /> : <Sort />}
                </th>
                <th
                  id="inquiryType"
                  onClick={() => sortBy('inquiries_types_label')}
                >INQUIRY TYPE {sortCriteria === 'inquiries_types_label' ? <ArrowDown width="10" transform={`rotate(${double ? '180' : '0'})`} /> : <Sort />}
                </th>
                <th
                  id="amount"
                  onClick={() => sortBy('amount')}
                >AMOUNT {sortCriteria === 'amount' ? <ArrowDown width="10" transform={`rotate(${double ? '180' : '0'})`} /> : <Sort />}
                </th>
                <th
                  id="fullName"
                  onClick={() => sortBy('contact_name')}
                >NAME {sortCriteria === 'contact_name' ? <ArrowDown width="10" transform={`rotate(${double ? '180' : '0'})`} /> : <Sort />}
                </th>
                <th
                  id="email"
                  onClick={() => sortBy('contact_email')}
                >EMAIL {sortCriteria === 'contact_email' ? <ArrowDown width="10" transform={`rotate(${double ? '180' : '0'})`} /> : <Sort />}
                </th>
                <th
                  id="network"
                  onClick={() => sortBy('network')}
                >NETWORK {sortCriteria === 'network' ? <ArrowDown width="10" transform={`rotate(${double ? '180' : '0'})`} /> : <Sort />}
                </th>
                <th
                  id="handle"
                  onClick={() => sortBy('account_handle')}
                >HANDLE {sortCriteria === 'account_handle' ? <ArrowDown width="10" transform={`rotate(${double ? '180' : '0'})`} /> : <Sort />}
                </th>
                <th
                  id="deadline"
                  onClick={() => sortBy('created_at')}
                >TIMESTAMP {sortCriteria === 'created_at' ? <ArrowDown width="10" transform={`rotate(${double ? '180' : '0'})`} /> : <Sort />}
                </th>
              </tr>
              {filterSubmissions.slice((page - 1) * submissionsPerPage, page * submissionsPerPage).map((submission) => (
                <tr onClick={() => openModal(submission.id)} key={submission.id}>
                  <td>{!submission?.readed
                    ? (<div className="close" />)
                    : null}
                  </td>
                  <td>{submission?.company}</td>
                  <td>{submission?.inquiries_types_label}</td>
                  <td>{submission?.amount}</td>
                  <td>{submission?.contact_name}</td>
                  <td>{submission?.contact_email}</td>
                  <td>{submission?.network}</td>
                  <td>{submission?.account_handle}</td>
                  <td>{new Date(submission?.created_at).toDateString()}</td>
                </tr>
              ))}
            </table>
            <div className="d-none d-lg-flex p-2 justify-content-between">
              <div className="pagination">
                {filterSubmissions.length > submissionsPerPage && Array.from({ length: Math.ceil(filterSubmissions.length / submissionsPerPage) }, (_, i) => i + 1)
                  .map((pageNumber) => (
                    <button
                      type="button"
                      key={pageNumber}
                      className={classnames('page-link', { active: pageNumber === page })}
                      onClick={() => setPage(pageNumber)}
                    >
                      {pageNumber}
                    </button>
                  ))}
              </div>
              <div className="show-selector-container">
                <label htmlFor>
                  <span className="mr-4 text-white">Show:</span>
                  <select value={submissionsPerPage} onChange={({ target }) => setSubmissionsPerPage(target.value)}>
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                    <option value={30}>30</option>
                    <option value={50}>50</option>
                    <option value={100}>100</option>
                  </select>
                </label>
              </div>
            </div>
            <div className="d-block d-lg-none overflow-hidden p-2">
              <FiltersMobile apliedFilters={apliedFilters} applyFilters={applyFilters} submissions={submissions} removeFilters={removeFilters} />
              <div
                className="mobile-home"
                onTouchMove={(e) => handleTouchMove(e)}
                onTouchStart={(e) => handleTouchStart(e)}
                onTouchEnd={handleTouchEnd}
              >
                {submissions.length <= 5 ? (
                  filterSubmissions.map((submission) => <MobileCard submission={submission} key={submission.id} onOpen={openModal} />)
                ) : (
                  <>
                    {step < 4 && !viewMore && filterSubmissions[step] && (
                    <>
                      <MobileCard submission={filterSubmissions[step]} key={filterSubmissions[step].id} onOpen={openModal} />
                    </>
                    )}
                    {step === 4 && !viewMore && (
                    <div className="d-flex justify-content-center p-5">
                      <button className="green-button" type="button" onClick={() => setViewMore(true)}>View All</button>
                    </div>
                    )}
                    {!viewMore
                && (
                  <StepperDots onSelect={(id) => trySetStep(id)} activeStep={step} stepsNumber={5} className="mt-3" />
                )}
                    {viewMore && modalContent === -1 && (
                    <div className="modal-container">
                      <div className="modal-deal">
                        <button type="button" className="close" aria-label="Close" onClick={() => setViewMore(false)}>
                          <span aria-hidden="true">&times;</span>
                        </button>
                        <div className="">
                          <h3 className="mb-5 text-secondary">All Submissions</h3>
                          <FiltersMobile apliedFilters={apliedFilters} applyFilters={applyFilters} submissions={submissions} removeFilters={removeFilters} />
                          { filterSubmissions.map((submission) => <MobileCard submission={submission} key={submission.id} onOpen={openModal} />)}
                        </div>
                      </div>
                    </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </>
        )
        : <p className="px-2">Looks like you have no form submissions yet. Make sure you add your Deal.me link to your social profiles!</p>}
    </>
  )
}

export default Submissions
