/* eslint-disable react/prop-types */
import './index.scss'

const ROOT_CLASS = 'stat-box'

const StatBox = ({ title, value }) => {
  return (
    <div className={`${ROOT_CLASS} col-6 p-3`}>
      <div>
        <h3 className="text-center py-3">{title}</h3>
        <h4 className="text-center">{value}</h4>
      </div>
    </div>
  )
}

export default StatBox
