/* eslint-disable radix */
/* eslint-disable react/prop-types */
import { useCallback, useEffect, useState } from 'react'
import { Formik, ErrorMessage } from 'formik'
import classnames from 'classnames'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Select from 'react-select'
import * as Yup from 'yup'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import axios from 'axios'
import { toast } from 'react-toastify'
import SpinnerCustom from '../common/SpinnerCustom'
import useUser from '../../hooks/useUser'
import translate from '../../i18next'
import { ReactComponent as GreenCheckMarkIcon } from '../../assets/icons/green_checkmark.svg'
import { ReactComponent as CopyIcon } from '../../assets/icons/copy-icon.svg'
import { ReactComponent as GoToIcon } from '../../assets/icons/goto.svg'
import { inquiryTypes } from '../../consts/inquiryTypes'

const copy = require('clipboard-copy')

const ROOT_CLASS = 'dealme-submission'
const DARK_GRAY_BORDERED_SELECT_CLASS = 'dealme-dark-gray-bordered-select'
const CAPTION_CONTAINER_CLASS = `${ROOT_CLASS}-right-side-caption-container`
const RIGHT_SIDE_INNER_CONTAINER_CLASS = `${ROOT_CLASS}-right-side-inner-container`
const SUCESS_MESSAGE_CONTAINER = `${RIGHT_SIDE_INNER_CONTAINER_CLASS}-success-message-container`
const FORM_CONTAINER_CLASS = `${ROOT_CLASS}-form-container`
const SUBMISSION_FORM_CLASS = `${FORM_CONTAINER_CLASS}-form`
const FORM_INPUT_ERROR_CLASS = `${SUBMISSION_FORM_CLASS}-input-error`
const FORM_INPUT_ERROR_MESSAGE_CLASS = `${SUBMISSION_FORM_CLASS}-input-error-message`
const SUBMISSION_FORM_SUBMIT_BUTTON_CLASS = `${SUBMISSION_FORM_CLASS}-button`

const INITIAL_FORM_VALUES = {
  fullName: '',
  email: '',
  companyName: '',
  websiteUrl: '',
  network: '',
  accountHandle: '',
  inquiryType: {},
  amount: '',
  deadline: '',
  message: ''
}

const FORM_SCHEMA = Yup.object().shape({
  fullName: Yup.string()
    .required('Required'),
  email: Yup.string()
    .email('Invalid email')
    .required('Required'),
  companyName: Yup.string()
    .required('Required'),
  websiteUrl: Yup.string()
    .required('Required'),
  network: Yup.string()
    .required('Required'),
  accountHandle: Yup.string()
    .required('Required'),
  inquiryType: Yup.object()
    .required('Required'),
  amount: Yup.string()
    .test(
      'Is only numbers',
      'ERROR: The amount must be a number',
      (value) => {
        const parts = value?.split('.')
        if (!parts) {
          return false
        }
        if (parts[1]) {
          return !parts[0].split('').some((char) => Number.isNaN(parseInt(char)))
          && !parts[1].split('').some((char) => Number.isNaN(parseInt(char))) && parts.length === 2
        }
        return !parts[0].split('').some((char) => Number.isNaN(parseInt(char)))
      }
    ).test(
      'Is only numbers',
      'ERROR: The format allowed for the amount is xxxxxx.xx',
      (value) => {
        const parts = value?.split('.')
        if (!parts) {
          return false
        }
        if (parts[1]) {
          return parts[0].length <= 6 && parts.length === 2 && parts[1].length <= 2
        }
        return parts[0].length <= 6
      }
    ),
  deadline: Yup.date()
    .required('Required')
    .min(new Date(new Date().setHours(0, 0, 0, 0)), 'Date should be greater or equal than today'),
  message: Yup.string()
    .max(500, 'Maximum of 500 chars')
    .required('Required')
})
// eslint-disable-next-line react/prop-types
const SubmissionForm = ({ isModal, content, submissionFormSlug, history, setImage, isEditView }) => {
  const [isFormSubmitted, setIsFormSubmitted] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const handleInquiryTypeSelectBlur = useCallback(({ setFieldTouched }) => () => {
    setFieldTouched('inquiryType', true)
  }, [])

  const handleInquiryTypeSelectChange = useCallback(({ setFieldValue }) => (selectedObject) => {
    setFieldValue('inquiryType', selectedObject)
  }, [])

  const API_URL = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}`
  const { userData } = useUser()

  axios.defaults.baseURL = API_URL
  axios.defaults.headers['Content-Type'] = 'application/json'
  axios.defaults.headers.Authorization = `bearer ${userData?.token}`

  const handleOnFormSubmit = useCallback((values) => {
    setIsLoading(true)
    const payload = {
      contact_name: values.fullName,
      contact_email: values.email,
      company: values.companyName,
      amount: values.amount,
      website: values.websiteUrl,
      network: values.network,
      account_handle: values.accountHandle,
      inquiries_types_id: values.inquiryType.value,
      engagement_dealine: values.deadline,
      message: values.message,
      submission_form_slug: submissionFormSlug
    }

    axios.create().post(
      'submission',
      payload
    )
      .then(() => {
        setIsFormSubmitted(true)
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [])

  const renderFormSubmitSuccessFeedback = useCallback(() => {
    return (
      <Container className={SUCESS_MESSAGE_CONTAINER}>
        <GreenCheckMarkIcon />
        <h2>{translate('components.Submission.SUCCESS_MESSAGE_CAPTION')}</h2>
        <p>{translate('components.Submission.SUCCESS_MESSAGE_NEXT_STEPS')}</p>
      </Container>
    )
  }, [])
  const [influencerName, setInfluencerName] = useState("the influencer's")
  useEffect(() => {
    const API_URL = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}`
    axios.defaults.baseURL = API_URL
    axios.defaults.headers['Content-Type'] = 'application/json'
    axios.defaults.headers.Authorization = `bearer ${userData?.token}`

    axios.create().get(
      `influencer/${submissionFormSlug}`
    )
      .then(({ data }) => {
        setInfluencerName(`${data.firstname} ${data.lastname}`)
        if (data.image && setImage) setImage(data.image)
      })
      .catch(() => {
        history?.push('/404')
      })
  },
  [])

  const copyText = (mail, text) => {
    copy(mail)
    toast.success(text)
  }

  return isFormSubmitted ? (
    renderFormSubmitSuccessFeedback()
  ) : (
    <>
      {isLoading && <SpinnerCustom />}
      {isModal ? (
        <h2 className="px-3">Submission Form</h2>
      )
        : (
          <Container className={CAPTION_CONTAINER_CLASS}>
            <h2>{translate('components.Submission.WELCOME_CAPTION', { influencerName })}</h2>
            <p>{translate('components.Submission.INSTRUCTIONS_CAPTION')}</p>
          </Container>
        )}
      <Container className={`${FORM_CONTAINER_CLASS}${isModal && !isEditView ? ' isModal' : ''}${isEditView ? ' isEdit' : ''}`}>
        { (!isModal || content || isEditView) && (
          <Formik
            onSubmit={handleOnFormSubmit}
            initialValues={content ? {
              fullName: content.contact_name,
              email: content.contact_email,
              companyName: content.company,
              amount: content.amount,
              websiteUrl: content.website,
              network: content.network,
              accountHandle: content.account_handle,
              inquiryType: inquiryTypes.find((inquiryType) => inquiryType.label === content.inquiries_types_label),
              deadline: content.engagement_dealine,
              message: content.message,
              submissionFormSlug: content.submission_form_slug
            } : INITIAL_FORM_VALUES}
            validationSchema={FORM_SCHEMA}
          >
            {({ values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldTouched, setFieldValue }) => (
              <Form noValidate className={SUBMISSION_FORM_CLASS} onSubmit={handleSubmit}>
                {isEditView && (
                <Form.Group as={Col} xs={12} md={6}>
                  <Form.Control
                    required
                    readOnly={isModal || isEditView}
                    className="withCopy"
                    value={`${window.location.origin}/s/${submissionFormSlug}`}
                  />
                  <CopyIcon
                    className="on-field-icon"
                    onClick={() => copyText(`${window.location.origin}/s/${submissionFormSlug}`, translate('components.GetStarted.LINK_COPIED'))}
                  />
                </Form.Group>
                )}
                <Row>
                  <Form.Group as={Col} xs={12} md={6}>
                    <Form.Label>* {translate('components.Submission.NAME_INPUT_LABEL')}</Form.Label>
                    <Form.Control
                      required
                      readOnly={isModal || isEditView}
                      type="text"
                      name="fullName"
                      autoComplete="given-name"
                      value={values.fullName}
                      className={classnames({ [FORM_INPUT_ERROR_CLASS]: errors.fullName && touched.fullName })}
                      placeholder={translate('components.Submission.NAME_INPUT_PLACEHOLDER')}
                      disabled={isLoading}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    <ErrorMessage name="fullName" className={FORM_INPUT_ERROR_MESSAGE_CLASS} component="p" />
                  </Form.Group>
                  <Form.Group as={Col} xs={12} md={6}>
                    <Form.Label>* {translate('components.Submission.EMAIL_INPUT_LABEL')}</Form.Label>
                    <Form.Control
                      required
                      readOnly={isModal || isEditView}
                      type="email"
                      name="email"
                      autoComplete="email"
                      value={values.email}
                      className={classnames({ [FORM_INPUT_ERROR_CLASS]: errors.email && touched.email })}
                      placeholder={translate('components.Submission.EMAIL_INPUT_PLACEHOLDER')}
                      disabled={isLoading}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    {isModal && !isEditView && <CopyIcon className="on-field-icon" onClick={() => copyText(values.email, translate('components.GetStarted.EMAIL_COPIED'))} />}
                    <ErrorMessage name="email" className={FORM_INPUT_ERROR_MESSAGE_CLASS} component="p" />
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group as={Col} xs={12} md={6}>
                    <Form.Label>* {translate('components.Submission.COMPANY_INPUT_LABEL')}</Form.Label>
                    <Form.Control
                      required
                      readOnly={isModal || isEditView}
                      type="text"
                      name="companyName"
                      value={values.companyName}
                      className={classnames({ [FORM_INPUT_ERROR_CLASS]: errors.companyName && touched.companyName })}
                      placeholder={translate('components.Submission.COMPANY_INPUT_PLACEHOLDER')}
                      disabled={isLoading}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    <ErrorMessage name="companyName" className={FORM_INPUT_ERROR_MESSAGE_CLASS} component="p" />
                  </Form.Group>
                  <Form.Group as={Col} xs={12} md={6}>
                    <Form.Label>* {translate('components.Submission.WEBSITE_INPUT_LABEL')}</Form.Label>
                    <Form.Control
                      required
                      readOnly={isModal || isEditView}
                      type="text"
                      name="websiteUrl"
                      autoComplete="url"
                      value={values.websiteUrl}
                      className={classnames({ [FORM_INPUT_ERROR_CLASS]: errors.websiteUrl && touched.websiteUrl })}
                      placeholder={translate('components.Submission.WEBSITE_INPUT_PLACEHOLDER')}
                      disabled={isLoading}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    <ErrorMessage name="websiteUrl" className={FORM_INPUT_ERROR_MESSAGE_CLASS} component="p" />
                    {isModal
                    && (
                    <a
                      target="_blank"
                      href={`${values.websiteUrl.startsWith('http://') || values.websiteUrl.startsWith('https://') ? '' : 'http://'}${values.websiteUrl}`}
                      rel="noreferrer"
                    > {!isEditView && <GoToIcon className="on-field-icon" />}
                    </a>
                    )}
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group as={Col} xs={12} md={6}>
                    <Form.Label>* {translate('components.Submission.NETWORK_INPUT_LABEL')}</Form.Label>
                    <Form.Control
                      required
                      readOnly={isModal || isEditView}
                      type="text"
                      name="network"
                      value={values.network}
                      className={classnames({ [FORM_INPUT_ERROR_CLASS]: errors.network && touched.network })}
                      placeholder={translate('components.Submission.NETWORK_INPUT_PLACEHOLDER')}
                      disabled={isLoading}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    <ErrorMessage name="network" className={FORM_INPUT_ERROR_MESSAGE_CLASS} component="p" />
                  </Form.Group>
                  <Form.Group as={Col} xs={12} md={6}>
                    <Form.Label>* {translate('components.Submission.ACCOUNT_HANDLE_INPUT_LABEL')}</Form.Label>
                    <Form.Control
                      required
                      readOnly={isModal || isEditView}
                      type="text"
                      name="accountHandle"
                      value={values.accountHandle}
                      className={classnames({ [FORM_INPUT_ERROR_CLASS]: errors.accountHandle && touched.accountHandle })}
                      placeholder={translate('components.Submission.ACCOUNT_HANDLE_INPUT_PLACEHOLDER')}
                      disabled={isLoading}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    <ErrorMessage name="accountHandle" className={FORM_INPUT_ERROR_MESSAGE_CLASS} component="p" />
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group as={Col} xs={12} md={6}>
                    <Form.Label>* {translate('components.Submission.INQUIRY_TYPE_INPUT_LABEL')}</Form.Label>
                    { isModal
                      ? (
                        <Form.Control
                          isMulti={false}
                          readOnly
                          isSearchable={false}
                          classNamePrefix={DARK_GRAY_BORDERED_SELECT_CLASS}
                          value={content?.inquiries_types_label}
                          type="text"
                        />
                      ) : (
                        <Select
                          isMulti={false}
                          readOnly={isModal || isEditView}
                          isSearchable={false}
                          classNamePrefix={DARK_GRAY_BORDERED_SELECT_CLASS}
                          value={values.inquiryType}
                          options={inquiryTypes}
                          onBlur={handleInquiryTypeSelectBlur({ setFieldTouched })}
                          onChange={handleInquiryTypeSelectChange({ setFieldValue })}
                        />
                      )}
                    <ErrorMessage name="inquiryType" className={FORM_INPUT_ERROR_MESSAGE_CLASS} component="p" />
                  </Form.Group>
                  {values.inquiryType.value !== 3 && (
                  <Form.Group as={Col} xs={12} md={6}>
                    <Form.Label>* {translate('components.Submission.AMOUNT_INPUT_LABEL')}</Form.Label>
                    <Form.Control
                      required={values.inquiryType.value !== 3}
                      readOnly={isModal || isEditView}
                      type="text"
                      step="1"
                      min="1"
                      name="amount"
                      value={values.amount}
                      className={classnames({ [FORM_INPUT_ERROR_CLASS]: errors.amount && touched.amount })}
                      placeholder={translate('components.Submission.AMOUNT_INPUT_PLACEHOLDER')}
                      disabled={isLoading}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      onInput={(e) => {
                        if (Number.isNaN(parseFloat(e.target.value))) {
                          e.target.value = ''
                        } else {
                          e.target.value = `${parseFloat(e.target.value)}.` === e.target.value ? e.target.value : parseFloat(e.target.value)
                        }
                      }}
                    />
                    <ErrorMessage name="amount" className={FORM_INPUT_ERROR_MESSAGE_CLASS} component="p" />
                  </Form.Group>
                  )}
                </Row>
                <Row>
                  <Form.Group as={Col} xs={12} md={6}>
                    <Form.Label>* {translate('components.Submission.DEADLINE_INPUT_LABEL')}</Form.Label>
                    {isModal ? (
                      <Form.Control
                        isMulti={false}
                        readOnly
                        isSearchable={false}
                        classNamePrefix={DARK_GRAY_BORDERED_SELECT_CLASS}
                        value={isEditView ? '' : new Date(values.deadline).toDateString()}
                        type="text"
                      />
                    ) : (
                      <>
                        <Form.Control
                          required
                          readOnly={isModal || isEditView}
                          type="date"
                          name="deadline"
                          value={values.deadline}
                          className={classnames({ [FORM_INPUT_ERROR_CLASS]: errors.deadline && touched.deadline })}
                          disabled={isLoading}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                        <ErrorMessage name="deadline" className={FORM_INPUT_ERROR_MESSAGE_CLASS} component="p" />
                      </>
                    )}
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group as={Col} xs={12} md={12}>
                    <Form.Label>* {translate('components.Submission.MESSAGE_INPUT_LABEL')}</Form.Label>
                    {isModal && !isEditView ? <p className="text-white">{content?.message ?? ''}</p> : (
                      <Form.Control
                        required
                        readOnly={isModal || isEditView}
                        as="textarea"
                        rows="8"
                        name="message"
                        value={values.message}
                        className={classnames({ [FORM_INPUT_ERROR_CLASS]: errors.message && touched.message })}
                        placeholder={translate('components.Submission.MESSAGE_INPUT_PLACEHOLDER')}
                        disabled={isLoading}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        onInput={(e) => {
                          e.target.value = e.target.value.slice(0, 500)
                        }}
                      />
                    )}
                    <ErrorMessage name="message" className={FORM_INPUT_ERROR_MESSAGE_CLASS} component="p" />
                  </Form.Group>
                </Row>
                {!isModal && (
                <Button
                  type="submit"
                  className={SUBMISSION_FORM_SUBMIT_BUTTON_CLASS}
                >
                  {translate('components.Submission.SUBMIT_BUTTON_CAPTION')}
                </Button>
                )}
                {isEditView
              && (
                <a href={`${window.location.origin}/s/${submissionFormSlug}`} className="containerEdit">
                  <Button
                    type="button"
                    className="isEditView"
                  >
                    Visit website form
                  </Button>
                </a>
              )}
              </Form>
            )}
          </Formik>
        )}
      </Container>
    </>
  )
}
export default SubmissionForm
