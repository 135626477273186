/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useState, useEffect } from 'react'
import axios from 'axios'
import { useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import useUser from '../../hooks/useUser'
import translate from '../../i18next'
import Stats from '../Stats'
import Submissions from '../Submissions'
import withSideBarMenu from '../SideBarMenu/withSideBarMenu'
import UserCTAs from '../UserCTAs'
import SpinnerCustom from '../common/SpinnerCustom'

import './index.scss'

function useQuery() {
  return new URLSearchParams(useLocation().search)
}

const ROOT_CLASS = 'dealme-home'

const Home = () => {
  const { setError, userData } = useUser()
  const [submissionsPerPage, setSubmissionsPerPage] = useState(10)
  const [page, setPage] = useState(1)
  const [submissions, setSubmissions] = useState([])
  const [influencers, setInfluencers] = useState([])
  const [influencer, setInfluencer] = useState()
  const [modalContent, setModalContent] = useState(-1)
  const [isLoading, setLoading] = useState(false)
  const [slugVisibility, setSlugVisibility] = useState(false)
  const isAdmin = userData?.user_type === 'super-admin' || userData?.user_type === 'admin'
  const IsManager = userData?.user_type === 'manager'

  const API_URL = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}`
  axios.defaults.baseURL = API_URL
  axios.defaults.headers['Content-Type'] = 'application/json'
  axios.defaults.headers.Authorization = `bearer ${userData?.token}`

  const getSubmissions = (slug, id) => {
    setLoading(true)
    axios.create().get(
      `submission/${slug}`
    )
      .then(({ data }) => {
        setSubmissions(data.submissions ?? [])
        if (id) {
          setModalContent(parseInt(id, 10))
          setInfluencer(influencers.find((influencer) => influencer.submission_form_slug === slug))
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          setError(error.response)
        }
        setSubmissions([])
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    setLoading(true)
    axios.create().get(
      `submission/${influencer?.submission_form_slug ?? userData?.submission_form_slug ?? ''}?pageSize=${submissionsPerPage}&page=${page}`
    )
      .then((/* { data } */) => {
        // setSubmissions(data.submissions ?? [])
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          // setError(error.response)
        }
        setSubmissions([])
      })
      .finally(() => {
        setLoading(false)
      })
  }, [submissionsPerPage, page])

  useEffect(() => {
    if (isAdmin) {
      setLoading(true)
      axios.create().get(
        'admin/user'
      )
        .then(({ data }) => {
          setInfluencers(data.filter((user) => user.user_type === 'influencer'))
        })
        .catch((error) => {
          toast.error(translate('components.Common.GENERAL_SERVER_ERROR'))
          if (error.response && error.response.status === 401) {
            setError(error.response)
          }
        })
        .finally(() => {
          setLoading(false)
          getSubmissions('')
        })
    } else if (IsManager) {
      setLoading(true)
      axios.create().get(
        'manager/influencer'
      )
        .then(({ data }) => {
          if (data && data.length > 0) {
            // console.log(data[0]?.submission_form_slug)
            setInfluencers(data)
            setInfluencer(data[0])
            getSubmissions(data[0]?.submission_form_slug ?? '')
          }
        })
        .catch((error) => {
          toast.error(translate('components.Common.GENERAL_SERVER_ERROR'))
          if (error.response && error.response.status === 401) {
            setError(error.response)
          }
        })
        .finally(() => {
          setLoading(false)
        })
    } else {
      getSubmissions(userData?.submission_form_slug ?? '')
    }
  },
  [])

  useEffect(() => {
    getSubmissions(influencer?.submission_form_slug ?? userData?.submission_form_slug ?? '')
  }, [influencer])

  const openMessages = submissions.filter((message) => message.readed).length
  const closedMessages = submissions.filter((message) => !message.readed).length
  const stats = [{
    title: 'Unopened',
    value: closedMessages
  },
  {
    title: 'Opened',
    value: openMessages
  }
  ]

  const query = useQuery()
  useEffect(() => {
    const slug = query.get('slug')
    const id = query.get('id')
    if (IsManager || isAdmin) {
      getSubmissions(slug, id)
    } else if (slug === userData?.submission_form_slug) {
      setModalContent(parseInt(id, 10))
    }
  },
  [])
  return (
    <div
      className={ROOT_CLASS}
      onClick={
        () => {
          if (slugVisibility) { setSlugVisibility(false) }
        }
      }
    >
      {isLoading && <SpinnerCustom />}
      <UserCTAs
        usertype={userData?.user_type}
        submissionFormSlug={influencer?.submission_form_slug ?? userData?.submission_form_slug}
        influencers={influencers}
        influencer={influencer}
        setInfluencer={setInfluencer}
        slugVisibility={slugVisibility}
        setSlugVisibility={setSlugVisibility}
      />
      <Stats stats={stats} />
      <Submissions
        submissions={submissions}
        submissionsPerPage={submissionsPerPage}
        setSubmissionsPerPage={setSubmissionsPerPage}
        page={page}
        setPage={setPage}
        setSubmissions={setSubmissions}
        submissionFormSlug={influencer?.submission_form_slug ?? userData?.submission_form_slug}
        modalContent={modalContent}
        setModalContent={setModalContent}
        initialModal={query.get('slug') && query.get('id')}
      />
    </div>
  )
}

export default withSideBarMenu({ defaultCollapsed: false })(Home)
