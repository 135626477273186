import { apiCall } from './api_client'
import { MANAGER_INVITATION, MANAGER_INVITATION_ACCEPT } from './auth.routes'

export const getInvitation = (idInvitation) => {
  return apiCall(`${MANAGER_INVITATION}/${idInvitation}`, {
    method: 'GET',
    immediately: true,
  })
}

export const acceptInvitation = (data) => {
  return apiCall(`${MANAGER_INVITATION_ACCEPT}`, {
    method: 'POST',
    immediately: true,
    data
  })
}
